<template>
  <!-- 加入我们 -->
  <div class="website-css phone-us">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <div class="join-us">
      <div class="return-head">
        <div @click="GoBack">{{ this.PositionList[0].GoBack }}</div>
      </div>
      <div class="w1200">
        <div class="join-us-left">
          <div class="title-div bor-bot-e5">
            <p class="title-p animated fadeInDown">
              {{ this.PositionList[this.num].title }}
            </p>
            <p>{{ this.PositionList[0].CompanyName }}</p>
            <p>{{ this.PositionList[0].Datatime }}：{{ this.datatime }}</p>
          </div>
          <div>
            <p>{{ this.PositionList[0].Subtitle1 }}：</p>
            <!-- <p>{{this.RightList[this.num] }}</p> -->
            <p
              v-for="(items, index) in this.PositionList[this.num].DutyList"
              :key="index"
            >
              {{ items.duty }}
            </p>
          </div>
          <div>
            <p>{{ this.PositionList[0].Subtitle2 }}：</p>
            <p
              v-for="(items, index) in this.PositionList[this.num]
                .RequirementList"
              :key="index"
            >
              {{ items.text }}
            </p>
          </div>
          <div class="email-all">
            <div class="email-div animated animated4s swing">
              <img src="../assets/image/Website-img/youxiang-icon.png" />
              {{ this.PositionList[0].resumeText }}：hr@eletell.com
            </div>
          </div>
        </div>

        <div class="join-us-right">
          <p>{{ this.PositionList[0].Hotpositions }}</p>
          <ul>
            <li
              v-for="(item, index) in this.PositionList"
              :key="index"
              :class="{ active: num == index }"
              @click="RightListclick(index)"
            >
              {{ item.title
              }}<span
                class="ji-span animated animated2s flash"
                v-if="item.span === true"
                >{{ item.HOT }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <web-bottom ref="child"></web-bottom>
  </div>
</template>
<script>
import ChinesePosition from '../assets/json/ChinesePosition-Json.json'
import EnglishPosition from '../assets/json/EnglishPosition-Json.json'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import MenuHead from '../components/MenuHead'
import WebBottom from '../components/WebBottom'

export default {
  name: 'Join-us',
  components: {
    WebBottom,
    MenuHead,
  },
  metaInfo: {
    title: '加入我们-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keyWords',
        content:
          '产品经理(APP),游戏UI设计师,认知科学算法分析师,信息流投放,ASO商店优化师,产品助理',
      },
      {
        name: 'description',
        content:
          '大象智酷科研团队依靠认知神经科学、脑科学、认知心理学等学科成熟理论,以数字云平台的大数据为依托,为不同行业群体提供针对性大脑能力评估和提升一体化方案.通过全方位、多能力的认知能力测试,实时采集相应数据,提供专业反馈信息和提升建议,用脑科学提升行业人群质量,赋能行业发展.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
      PositionList: [],
      ChinesePosition: JSON.parse(JSON.stringify(ChinesePosition)),
      EnglishPosition: JSON.parse(JSON.stringify(EnglishPosition)),
      num: this.$route.query.index,
      itenList: [],
      datatime: '',
    }
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.PositionList = this.ChinesePosition
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      console.log('语言类型', this.langtype)
      this.IFType()
    }
  },
  mounted() {
    // this.RightListclick();this.datatime = new Date();
    this.datatime = new Date().Format('yyyy-MM-dd')
    // this.getList();
  },
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        this.PositionList = this.ChinesePosition
        console.log('中文配表', this.languageJson)
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.PositionList = this.EnglishPosition
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      console.log('home', data)
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'AboutUs') {
          this.languageList = item.List[0]
        }
      })
      console.log(this.languageList)
    },
    GoBack() {
      this.$router.push({ path: '/AboutUs' })
    },
    RightListclick(index) {
      console.log(index)
      this.num = index

      // this.itenList = item;
    },
  },
}
</script>

<style scoped>
.join-us {
  margin-top: 60px;
  background: #f6f6f6;
}
.active {
  color: #1a99fc;
  border-bottom: 1px solid #1a99fc;
}
.w1200 {
  padding: 25px;
  display: flex;
}
</style>